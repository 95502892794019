
import requests from './config';

//getCodeImg
export const getCodeImg = (data) => requests({ url: '/user/captcha', data, method: 'post' });

//修改密码
export const updatePassWord = (data) => requests({ url: '/sysUser/updatePassWord', data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//登录
export const reqUserLogin = (data) => requests({ url: '/user/login', data, method: 'post' });

//登出
export const reqLogout = () => requests({ url: '/user/logout', method: 'get' });

//验证码
export const reqCode = (data) => requests({ url: `/user/code?time=${data}`, method: 'get', responseType: 'arraybuffer', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//获取菜单列表
export const reqMainMenus = () => requests({ url: '/sysMenu/mainMenus', method: 'get' });

// 菜单列表查询所有菜单
export const reqGetMenus = (data) => requests({ url: `/sysMenu/getMenus?roleId=${data}`, method: 'get' });

//获取所有用户信息
export const reqUsers = (data) => requests({ url: `/sysUser/getUsers?${data}`, method: 'get' })

//获取所有部门信息
export const reqDeptList = () => requests({ url: '/sysDept/getDeptList', method: 'get' });

//获取部门信息 （部门id，部门名称）
export const reqDeptInfo = () => requests({ url: '/sysDept/getDeptCode', method: 'get' });

//根据部门查询所有角色及以下用户
export const reqRoleList = (data) => requests({ url: `/sysRole/getRoleList?deptId=${data}`, method: 'get' });

//新增用户
export const setAddUsers = (data) => requests({ url: `/sysUser/addUsers?`, data, method: 'post' });

//修改用户 /sysUser/updateUsers
export const setRevampUsers = (data) => requests({ url: `/sysUser/updateUsers`, data, method: 'post' });

//根据用户查询角色部门 /sysUserRole/getRoleDept
export const getRoleDept = (data) => requests({ url: `/sysUserRole/getRoleDept?${data}`, method: 'get' });

//添加角色用户
export const addUserRole = (data) => requests({ url: `/sysUserRole/addUserRole`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//新增部门
export const addDeptS = (data) => requests({ url: `/sysDept/addDept`, data, method: 'post' });

//删除部门 批量删除字符串以逗号分隔
export const delDept = (data) => requests({ url: `/sysDept/delDept`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//修改部门
export const updateDept = (data) => requests({ url: `/sysDept/updateDept`, data, method: 'post' });

//新增部门下角色
export const addRole = (data) => requests({ url: `/sysRole/addRole`, data, method: 'post' });

//删除部门下角色信息 批量删除字符串以逗号分隔
export const delRoles = (data) => requests({ url: `/sysRole/delRoles`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//修改角色
export const updateRole = (data) => requests({ url: `/sysRole/updateRole`, data, method: 'post' });

//批量删除用户(多ID使用逗号分隔)
export const delUsers = (data) => requests({ url: `/sysUser/delUsers`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//添加角色菜单
export const upRolePower = (data) => requests({ url: `/sysRoleMenu/addRoleMenu`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//查询操作日志
export const getOperationList = (data) => requests({ url: `/operationLog/getOperationList?${data}`, method: 'get' });

//删除操作日志
export const delOperation = (data) => requests({ url: `/operationLog/delOperation`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

// 查询所有流程信息 @parma deptId，pageNum，pageSize
export const getTblFlowList = (data) => requests({ url: `/tblFlow/getTblFlowList?${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//新增流程信息
export const addTblFlow = (data) => requests({ url: `/tblFlow/addTblFlow`, data, method: 'post' });

//批量删除流程 flowIds
export const delTblFlow = (data) => requests({ url: `/tblFlow/delTblFlow?flowIds=${data}`, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//修改流程信息
export const updateTblFlow = (data) => requests({ url: `/tblFlow/updateTblFlow`, data, method: 'post' });

//新建申请单
export const addTblForm = (data) => requests({ url: `/tblForm/addTblForm`, data, method: 'post' });

//提交申请单
export const commitTblForm = (data) => requests({ url: `/tblForm/commitTblForm`, data, method: 'post' });

//撤回申请单
export const retractTblForm = (data) => requests({ url: `/tblForm/retractTblForm`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//查询我的申请单
export const getTblFormList = (data) => requests({ url: `/tblForm/getTblFormList?${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//查询绩效
export const getPerformanceList = (data) => requests({ url: `/tblForm/getPerformanceList?${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//查询待我审核(param 0全部，1未审核，2已审核)
export const getNoTblAudit = (data) => requests({ url: `/tblAudit/getNoTblAudit?${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//通过审核事件
export const approvedTblAudit = (data) => requests({ url: `/tblAudit/approvedTblAudit`, data, method: 'post' });

//批量删除申请单 
export const delTblForm = (data) => requests({ url: `/tblForm/delTblForm`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//根据ID查询申请单信息
export const getTblFormById = (data) => requests({ url: `/tblForm/getTblFormById?formId=${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//驳回审核事件
export const rejectTblAudit = (data) => requests({ url: `/tblAudit/rejectTblAudit`, data, method: 'post' });

//查看审核意见详情
export const getTblAuditDetails = (data) => requests({ url: `/tblAudit/getTblAuditDetails?formId=${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//抄送表单（新建流程节点）
export const makeCopyTblAudit = (data) => requests({ url: `/tblNode/makeCopyTblAudit`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//我的执行
export const myExecuteTblForm = (data) => requests({ url: `/tblForm/myExecuteTblForm`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//执行申请单
export const executeTblForm = (data) => requests({ url: `/tblForm/executeTblForm`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//文件下载
export const download = (data) => requests({ url: `/uploadImg/download`, data, method: 'post', responseType: 'blob', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//导出请假记录
export const exportVacationExcel = (startTime,endTime) => requests({ url: `/vacation/exportVacationExcel?startTime=${startTime}&endTime=${endTime}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },responseType: 'blob' });

//一键发送绩效
export const sendPerformance = (data) => requests({ url: `/tblForm/sendPerformance`, data, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//修改申请单
export const updateTblForm = (data) => requests({ url: `/tblForm/updateTblForm`, data, method: 'post' });

//文件删除
export const deleteFile = (data) => requests({ url: `/uploadImg/delete?fileName=${data}`, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//被驳回后重新提交申请单
export const continueTblForm = (data) => requests({ url: `/tblForm/continueTblForm`, data, method: 'post' });

//查询用户信息和绑定的绩效流程
export const getUserPerformance = () => requests({ url: `/sysUser/getUserPerformance`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//导出excel
export const exportAssignTemplateExcel = (data) => requests({ url: `/export/exportAssignTemplateExcel?formIds=${data}`, method: 'get', headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8;' }, responseType: 'blob' });

//根据流程id查询流程节点信息
export const getNodeListByFlowId = (data) => requests({ url: `tblNode/getNodeListByFlowId?flowId=${data}`, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });

//抄送表单（新建流程节点）
export const makeCopyNodeList = (flowId,jointContent,data) => requests({ url: `tblNode/makeCopyNodeList?flowId=${flowId}&jointContent=${jointContent}`,data, method: 'post',headers: { 'Content-Type': 'application/json;charset=UTF-8' }});

//查询商机列表
export const getBusinessList = (data) => requests({ url: `business/getBusinessList?${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//新增或修改商机
export const addBusiness = (data) => requests({ url: `business/addOrUpdateBusiness`,data, method: 'post'});

///新增跟进
export const addFollow = (data) => requests({ url: `business/addFollow`,data, method: 'post'});

//根据商机ID查询跟进详情
export const getFollowById = (data) => requests({ url: `business/getFollowById?businessId=${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//根据id查看详情
export const getBusinessById = (data) => requests({ url: `business/getBusinessById?businessId=${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//修改跟进
export const updateFollow = (data) => requests({ url: `business/updateFollow`,data, method: 'post'});

//删除商机
export const delBusiness = (data) => requests({ url: `business/delBusiness?businessIds=${data}`, method: 'post',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询所有省
export const getProvinceList = () => requests({ url: `address/getProvinceList`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//根据省查询市
export const getCityList = (data) => requests({ url: `address/getCityList?provinceCode=${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//根据市查询区
export const getAreaList = (data) => requests({ url: `address/getAreaList?citycode=${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//下载商机导入模板
export const downLoadTemplate = () => requests({ url: `business/downLoadTemplate`, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded charset=utf-8;' }, responseType: 'blob' });

//商机模板导入
export const importData = (data) => requests({ url: `business/importData`,data, method: 'post',headers: { 'Content-Type': 'multipart/form-data' }});

//生成请假时长
export const generationTime = (startTime,endTime) => requests({ url: `wx-crm/generationTime?startDate=${startTime}&endDate=${endTime}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询用户剩余假期（1.年假，2.病假）
export const getBalanceByUser = (data) => requests({ url: `balance/getBalanceByUser?type=${data}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//请假后修改余额（1.年假，2.病假）
export const lastUpdateBalance = (data) => requests({ url: `balance/lastUpdateBalance`,data, method: 'post'});

//请假统计-日周月
export const getVacationList = (startTime, endTime) => requests({ url: `vacation/getVacationList?startTime=${startTime}&endTime=${endTime}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询年假余额统计
export const getBalanceList = (nickName) => requests({ url: `balance/getBalanceList?nickName=${nickName}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//删除年假余额
export const delBalance = (data) => requests({ url: `balance/delBalance?ids=${data}`, method: 'post', headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//修改年假余额
export const updateBalance = (data) => requests({ url: `balance/updateBalance`,data, method: 'post'});

//新增年假余额
export const addBalance = (data) => requests({ url: `balance/addBalance`,data, method: 'post'});

//获取所有用户信息（请假余额）
export const reqUsersBalance = (data) => requests({ url: `/balance/getUsers?${data}`, method: 'get' })

//年假导入
export const importHolidayData = (data) => requests({ url: `balance/importData`,data, method: 'post',headers: { 'Content-Type': 'multipart/form-data' }});

//查询用户信息和绑定的绩效流程
export const getUserPerformance2 = (nickName) => requests({ url: `performance/getUserPerformance2?pageNum=1&pageSize=999&nickName=${nickName}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//流程设置查询
export const getPerSetupList = (userIds) => requests({ url: `performance/getPerSetupList?userIds=${userIds}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询所有绩效流程信息
export const getPerFlowList = () => requests({ url: `performance/getPerFlowList`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//修改用户绑定的绩效流程
export const updatePerformance = (data) => requests({ url: `performance/updatePerformance`,data, method: 'post'});

//新增用户绑定的绩效流程
export const addPerformance = (data) => requests({ url: `performance/addPerformance`,data, method: 'post'});

//查询商机通知列表
export const getBusinessNotice = (keyword,pageNum,pageSize) => requests({ url: `notice/getBusinessNotice?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//选中设置商机
export const chooseSetNotice = (data) => requests({ url: `notice/chooseSetNotice`,data, method: 'post'});

//全选设置商机
export const allSetNotice = (isNotice) => requests({ url: `notice/allSetNotice?isNotice=${isNotice}`, method: 'post',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询商机勘探列表(state 1.未审核，2.已通过,3.已拒绝)
export const getBusinessExplorate = ({keyword,pageNum,pageSize,state}) => requests({ url: `explorate/getBusinessExplorate?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}&state=${state}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//新增提交勘探
export const addExplorate = (data) => requests({ url: `explorate/addExplorate`,data, method: 'post'});


//查看会审意见
export const getTblJointList = (flowId) => requests({ url: `tblJoint/getTblJointList?flowId=${flowId}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//查询驳回时选择流程上的人
export const getRejectUser = (formId) => requests({ url: `tblAudit/getRejectUser?formId=${formId}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//被回退后重新通过申请单
export const approvedTblAuditAgain = (data) => requests({ url: `tblAudit/approvedTblAuditAgain`, method: 'post',data});


//查询所有项目或品牌
export const getFieldList = (fromType,keyword) => requests({ url: `field/getFieldList?fromType=${fromType}&keyword=${keyword}&pageNum=1&pageSize=9999`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//新增项目或品牌
export const addField = (data) => requests({ url: `field/addField`, method: 'post',data});

//删除项目或品牌
export const delField = (id) => requests({ url: `field/delField?id=${id}`, method: 'post',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//修改项目或品牌
export const updateField = (data) => requests({ url: `field/updateField`, method: 'post',data});

//查询采购阶段
export const getBuyStage = (formId) => requests({ url: `buyStage/getBuyStage?formId=${formId}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});

//新增采购阶段
export const addBuyStage = (data) => requests({ url: `buyStage/addBuyStage`, method: 'post',data});

//查询我的申请单-用于采购
export const getTblFormListBuy = (formId,stampType) => requests({ url: `tblForm/getTblFormListBuy?formId=${formId}&stampType=${stampType}`, method: 'get',headers: { 'Content-Type': 'application/x-www-form-urlencoded' }});


//查询项目列表信息
export const getProjectList = (params) => requests({ url: `tbl-project/getProjectList`, method: 'get', params});

//文件上传
export const fileUpload = (data) => requests({
    url: `uploadImg/fileUpload`,
    method: 'post',
    data
});

//新增公告
export const addNotice = (data) => requests({ url: `t-notice/addNotice`, method: 'post',data});

//查询所有公告
export const getNoticeList = (params) => requests({ url: `t-notice/getNoticeList`, method: 'get', params});

//删除公告
export const delNotice = (noticeIds) => requests({ url: `t-notice/delNotice?noticeIds=${noticeIds}`, method: 'post'});

//修改公告—发布state传2
export const updateNotice = (data) => requests({ url: `t-notice/updateNotice`, method: 'post',data});

//新增公告已读
export const addNoticeRead = (noticeId) => requests({ url: `wx-crm/addNoticeRead?noticeId=${noticeId}`, method: 'post'});

//查询所有公告-isRead1已读
export const getNoticeReadList = (params) => requests({ url: `wx-crm/getNoticeReadList`, method: 'get', params});
