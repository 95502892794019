<template>
    <div class="watermark">
        <div v-for="item in 100" :key="item" class="block">
            <div v-for="item2 in 30" :key="item2" class="item">
                <div class="p">
                    {{ nikeName }}
                </div>
                <div class="p">
                    {{ time }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'watermark',
    data() {
        return {
            time: ''
        }
    },
    computed: {
        ...mapState({
            nikeName: (state) => state.user.nikeName,
        })
    },
    mounted() {
        this.getTime();
        setInterval(() => {
            this.getTime();
        }, 1000);
    },
    methods: {
        getTime() {
            let stamp = new Date().getTime() + 8 * 60 * 60 * 1000;
            this.time = new Date(stamp).toISOString().replace(/T/, ' ').replace(/\..+/, '').substring(0, 16);
        }
    }
}
</script>

<style scoped lang="less">
.watermark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    /* 禁用点击事件 */
    white-space: nowrap;


    .block {
        display: flex;

        .item {
            width: 100px;
            margin: 0% 12% 13% 0;
            transform: rotate(-30deg);
            // color: #80808042;
            color: #bbbbbb50;
            text-align: center;
            font-size: 12px;

            .p {
                margin-bottom: 6px;
            }
        }
    }
}
</style>